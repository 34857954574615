<template>
    <div v-if="data.type === 'EY Podcasts'" class="h-auto w-full">
        <Podcast :data="data" />
    </div>
    <div v-else class="h-auto w-ful">
        <Comm :data="data" />
    </div>
</template>

<script>
const Comm = () => import('@/components/Globals/PersonalDashboard/Comm');
const Podcast = () => import('@/components/Globals/PersonalDashboard/Podcast.vue');

export default {
    props:['data'],
    components:{
        Comm,
        Podcast
    },
}
</script>